import React from "react";
import { Box, Container, Flex, HStack, Spacer, Text } from "@chakra-ui/react";

const BulkOrderStrip = () => {
  return (
    <Box bgColor={"#52bb6a"} w={"full"} py={4}>
      <Container maxW={"6xl"}>
        <Flex>
          <Text fontSize={{ lg: "1.3rem", md: "1.3rem", sm: "0.9rem", base: "0.6rem" }} fontWeight={"bold"} color={"white"}>
            Want Vidya Box in your school?
          </Text>
          <Spacer />
          <HStack>
            <Text fontSize={{ lg: "1.2rem", md: "1.2rem", sm: "0.8rem", base: "0.5rem" }} color={"white"}>
              Call Now:
            </Text>{" "}
            <Text fontSize={{ lg: "1.3rem", md: "1.3rem", sm: "0.9rem", base: "0.6rem" }} fontWeight={"bold"} color={"white"}>
              +91 9799166556
            </Text>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default BulkOrderStrip;
