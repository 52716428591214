import React from "react";
import {
  Flex,
  Text,
  Container,
  Stack,
  Collapse,
  Icon,
  Link,
  useDisclosure,
  HStack,
  Spacer,
  Button,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import Logo from "./logo";

const Links = [
  {
    name: "HOME",
    path: "/",
  },
  {
    name: "WEB PLAYER",
    path: "/web-player",
  },
  {
    name: "ABOUT US",
    path: "/about-us",
  },
  {
    name: "FELLOWSHIP",
    path: "/fellowship"
  }
];


export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  
  const NavLink = ({ name, path }) => (
    <a href={path}>
      <Button
        px={2}
        py={1}
        rounded={"md"}
        fontWeight={"bold"}
        fontSize={"0.86rem"}
        bgColor={"white"}
        // onClick={() => navigate(path)}
        _hover={{
          textDecoration: "none",
          bg: "gray.200",
        }}
      >
        {name}
      </Button>
    </a>
  );

  return (
    <Container maxW={"6xl"} py={"4"}>
      <Flex display={{ base: "none", sm: "none", md: "flex", lg: "flex" }}>
        <a href="/">
          <Logo />
        </a>
        <Spacer />
        <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
          {[
            ...Links.map((link) => (
              <NavLink name={link.name} path={link.path} />
            )),
            <a target={"_blank"} href="https://forms.gle/qzwmPfX4EUbnYeVeA">
              <Button
                fontSize={"0.86rem"}
                fontWeight={"bold"}
                color={"white"}
                _hover={{
                  textDecoration: "none",
                  bg: "#71d387",
                }}
                bgColor={"#52bb6a"}
              >
                BULK ORDER
              </Button>
            </a>,
          ]}
        </HStack>
      </Flex>
      <HStack px={0} display={{ lg: "none", md: "none", sm: "flex", base: "flex" }}>
        <Logo />
        <Spacer />
        <IconButton
          onClick={onToggle}
          icon={
            isOpen ? <CloseIcon w={5} h={5} /> : <HamburgerIcon w={5} h={5} />
          }
          variant={"ghost"}
          aria-label={"Toggle Navigation"}
        />
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Container>
  );
}

const MobileNav = () => {
  return (
    <Stack bg={"white"} p={4} display={{ md: "none" }}>
      {Links.map((navItem) => (
        <MobileNavItem key={navItem.name} label={navItem.name} href={navItem.path} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={600} color={"gray.600"}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={"gray.200"}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};