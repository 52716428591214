import React from "react";
import BulkOrderStrip from "../components/index/bulk_order_strip";
import Footer from "../components/index/footer";
import Header from "../components/index/header";

const MasterLayout = ({ children }) => {
  return (
    <>
      <BulkOrderStrip />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default MasterLayout;
