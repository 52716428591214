import React from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  GridItem,
} from "@chakra-ui/react";
import { FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from "react-icons/fa";
import PlayStoreBadge from "../icons/playstore_badge";
import Logo from "./logo";
import AppStoreBadge from "../icons/appstore_badge";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={"blackAlpha.100"}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "blackAlpha.200",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box bg={"gray.50"} color={"gray.700"}>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 2, sm: 2, md: 4 }} spacing={8}>
          <GridItem colSpan={{ lg: 1, md: 1, sm: 2, base: 2 }}>
            <Stack spacing={2}>
              <Logo />
              <Text fontSize={"sm"}>Listen & Learn with SunoKitaab</Text>
            </Stack>
          </GridItem>

          <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Link href={"/about-us"}>About Us</Link>
            <Link target={"_blank"} href={"https://sunokitaab.freshteam.com/jobs"}>Careers</Link>
            <Link href={"/contact-us"}>Contact Us</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Legal</ListHeader>
            <Link href={"/privacy-policy"}>Privacy Policy</Link>
            <Link href={"/terms-and-conditions"}>Terms & Conditions</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Install App</ListHeader>
            <Link
              target={"_blank"}
              href="https://sunokitaab.com/app"
            >
              <PlayStoreBadge />
            </Link>
            {/* <a href="https://apps.apple.com/us/app/sunokitaab-ncert-audiobooks/id6444225774">
              <AppStoreBadge />
            </a> */}
          </Stack>
        </SimpleGrid>
      </Container>

      <Box borderTopWidth={1} borderStyle={"solid"} borderColor={"gray.200"}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ md: "space-between" }}
          align={{ md: "center" }}
        >
          <Text>
            © {new Date().getFullYear()} SunoKitaab Pvt. Ltd. All rights
            reserved
          </Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Twitter"}
              href={"https://twitter.com/sunokitaab"}
            >
              <FaTwitter />
            </SocialButton>
            <SocialButton
              label={"LinkedIn"}
              href={"https://in.linkedin.com/company/sunokitaab"}
            >
              <FaLinkedin />
            </SocialButton>
            <SocialButton
              label={"YouTube"}
              href={"https://www.youtube.com/c/SunoKitaab"}
            >
              <FaYoutube />
            </SocialButton>
            <SocialButton
              label={"Instagram"}
              href={"https://www.instagram.com/sunokitaab"}
            >
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
